import React, { useMemo } from 'react';
import classnames from 'classnames';

import classes from './EnergyRatingLabel.scss';

type EnergyRatingLabelProps = {
    letter?: string;
    specificationLink?: string | null;
    energyLabelImage?: string | null;
    inProductList?: boolean;
    variant?: 'basket' | 'category-list' | 'pdp';
};

export function EnergyRatingLabel({ letter, specificationLink, energyLabelImage, variant }: EnergyRatingLabelProps) {
    const [showComponent, wrapperClasses] = useMemo(() => {
        const ratingClass = letter && letter.length > 0 ? classes[`wrapperRating${letter}`] : false;

        if (ratingClass) {
            return [
                true,
                classnames({
                    [classes.wrapper]: true,
                    [ratingClass]: true,
                    [classes.wrapperSmall]: variant !== 'pdp',
                }),
            ];
        } else {
            return [false, undefined];
        }
    }, [letter, variant]);

    const wrapperContent = (
        <>
            <span className={classes.scale}>
                <span>A</span>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                </span>
                <span>G</span>
            </span>
            <span className={classes.letter}>{letter}</span>
        </>
    );

    if (showComponent) {
        return (
            <div className={classes.energyLabel} data-variant={variant}>
                {!!energyLabelImage ? (
                    <a className={wrapperClasses} href={energyLabelImage} rel="noreferrer" target="_blank">
                        {wrapperContent}
                    </a>
                ) : (
                    <div className={wrapperClasses}>{wrapperContent}</div>
                )}
                {specificationLink && specificationLink.length > 0 && (
                    <a
                        href={specificationLink}
                        rel="noreferrer"
                        target="_blank"
                        className={classes.productSpecificationSheet}
                    >
                        Product Information Sheet
                    </a>
                )}
            </div>
        );
    } else {
        return null;
    }
}
